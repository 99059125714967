import { __decorate, __extends } from "tslib";
import { Component, Watch, Vue } from 'vue-property-decorator';
import Pagination from '@/components/Pagination.vue';
import { supportModule } from '@/store/modules/support';
import { Message } from 'element-ui';
var MaintainQuery = (function (_super) {
    __extends(MaintainQuery, _super);
    function MaintainQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.page = 1;
        _this.rows = 50;
        _this.mobile = '';
        _this.password = '';
        return _this;
    }
    Object.defineProperty(MaintainQuery.prototype, "maintainRecord", {
        get: function () {
            return supportModule.maintainRecord;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MaintainQuery.prototype, "total", {
        get: function () {
            return supportModule.maintainRecordTotal;
        },
        enumerable: false,
        configurable: true
    });
    MaintainQuery.prototype.pageWatch = function () {
        this.searchMaintainRecord();
    };
    MaintainQuery.prototype.statusFormat = function (_a) {
        var status = _a.status;
        if (status === 1) {
            return '未处理';
        }
        else if (status <= 21) {
            return '已确认';
        }
        else if (status <= 41) {
            return '维修中';
        }
        else if (status <= 61) {
            return '已发货';
        }
        return '';
    };
    MaintainQuery.prototype.searchMaintainRecord = function () {
        window.scrollTo({
            top: 500,
            behavior: 'smooth'
        });
        var message = Message({
            message: this.$t('maintainQuery.searching'),
            type: 'info',
            duration: 0
        });
        supportModule.getMaintainRecord({
            page: this.page,
            rows: this.rows,
            mobile: this.mobile,
            password: this.password
        }).finally(function () {
            message.close();
        });
    };
    __decorate([
        Watch('$data.page')
    ], MaintainQuery.prototype, "pageWatch", null);
    MaintainQuery = __decorate([
        Component({
            components: { Pagination: Pagination }
        })
    ], MaintainQuery);
    return MaintainQuery;
}(Vue));
export default MaintainQuery;
