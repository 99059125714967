import { __decorate, __extends } from "tslib";
import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator';
var TabList = (function (_super) {
    __extends(TabList, _super);
    function TabList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TabList.prototype.idWatch = function () {
        this.getData();
    };
    TabList.prototype.updateID = function (data) {
        return data.id;
    };
    TabList.prototype.setPage = function () {
        return 1;
    };
    TabList.prototype.getData = function () {
        return this.id;
    };
    TabList.prototype.tabClick = function (data) {
        this.updateID(data);
        this.setPage();
    };
    TabList.prototype.tabChange = function (event) {
        this.updateID(event.target.value);
        this.setPage();
    };
    __decorate([
        Prop({ required: true })
    ], TabList.prototype, "titleList", void 0);
    __decorate([
        Prop({ required: true })
    ], TabList.prototype, "id", void 0);
    __decorate([
        Watch('$props.id')
    ], TabList.prototype, "idWatch", null);
    __decorate([
        Emit('update:id')
    ], TabList.prototype, "updateID", null);
    __decorate([
        Emit()
    ], TabList.prototype, "setPage", null);
    __decorate([
        Emit()
    ], TabList.prototype, "getData", null);
    TabList = __decorate([
        Component
    ], TabList);
    return TabList;
}(Vue));
export default TabList;
