import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { addSuggest } from '@/api/support/suggest';
import { Message } from 'element-ui';
var ComplaintAdvice = (function (_super) {
    __extends(ComplaintAdvice, _super);
    function ComplaintAdvice() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = {};
        _this.backspace = /\s/;
        _this.mobile = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        _this.rules = {
            linkman: {
                required: true,
                message: _this.$t('complaintAdvice.message'),
                trigger: 'blur'
            },
            mobile: [
                {
                    required: true,
                    message: _this.$t('complaintAdvice.message'),
                    trigger: 'blur'
                }
            ],
            hw_order: {
                required: true,
                message: _this.$t('complaintAdvice.message'),
                trigger: 'blur'
            },
            hw_model: {
                required: true,
                message: _this.$t('complaintAdvice.message'),
                trigger: 'blur'
            },
            hw_description: {
                required: true,
                message: _this.$t('complaintAdvice.message'),
                trigger: 'blur'
            },
            hw_opinion: {
                required: true,
                message: _this.$t('complaintAdvice.message'),
                trigger: 'blur'
            }
        };
        return _this;
    }
    ComplaintAdvice.prototype.mobileCheck = function (rule, value, callback) {
        if (value) {
            if (this.backspace.test(value)) {
                return callback(this.$t('complaintAdvice.backspace'));
            }
            else if (!this.mobile.test(value)) {
                return callback(this.$t('complaintAdvice.mobile'));
            }
            callback();
        }
        else {
            callback();
        }
    };
    ComplaintAdvice.prototype.submit = function () {
        var _this = this;
        this.$refs.form.validate(function (valid, object) {
            if (valid) {
                var message_1 = Message({
                    message: _this.$t('complaintAdvice.uploading'),
                    type: 'info',
                    duration: 0
                });
                addSuggest(_this.form).
                    then(function () {
                    _this.$message({
                        message: _this.$t('complaintAdvice.uploadSuccess'),
                        type: 'success'
                    });
                    _this.reset();
                }).
                    finally(function () {
                    message_1.close();
                });
            }
            else {
                var name = object[Object.keys(object)[0]][0].field;
                _this.$refs[name][0].focus();
            }
        });
    };
    ComplaintAdvice.prototype.reset = function () {
        this.$refs.form.resetFields();
        this.form = {};
    };
    ComplaintAdvice = __decorate([
        Component
    ], ComplaintAdvice);
    return ComplaintAdvice;
}(Vue));
export default ComplaintAdvice;
