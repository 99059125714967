import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import TabList from '@/components/TabList.vue';
import CustomerService from '@/components/CustomerService.vue';
import DownloadSection from '@/components/DownloadSection.vue';
import ComplaintAdvice from '@/components/ComplaintAdvice.vue';
import MaintainQuery from '@/components/MaintainQuery.vue';
import { systemModule } from '@/store/modules/system';
import { supportModule } from '@/store/modules/support';
var ServiceSupport = (function (_super) {
    __extends(ServiceSupport, _super);
    function ServiceSupport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ServiceSupport.prototype, "currentID", {
        get: function () {
            return supportModule.serviceID;
        },
        set: function (val) {
            supportModule.setServicetID(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ServiceSupport.prototype, "navList", {
        get: function () {
            return systemModule.navList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ServiceSupport.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    ServiceSupport.prototype.getComponent = function () {
        var params = {
            15: CustomerService,
            16: DownloadSection,
            18: ComplaintAdvice,
            48: MaintainQuery
        };
        return params[this.currentID];
    };
    ServiceSupport = __decorate([
        Component({
            components: { CustomHead: CustomHead, TabList: TabList }
        })
    ], ServiceSupport);
    return ServiceSupport;
}(Vue));
export default ServiceSupport;
