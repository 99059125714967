import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { supportModule } from '@/store/modules/support';
var DownloadSection = (function (_super) {
    __extends(DownloadSection, _super);
    function DownloadSection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DownloadSection.prototype.mounted = function () {
        supportModule.getDocumentParams();
    };
    Object.defineProperty(DownloadSection.prototype, "documentList", {
        get: function () {
            return Object.values(supportModule.documentParams).
                sort(function (a, b) { return b.length - a.length; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadSection.prototype, "ids", {
        get: function () {
            return [
                50,
                30,
                29
            ];
        },
        enumerable: false,
        configurable: true
    });
    DownloadSection = __decorate([
        Component
    ], DownloadSection);
    return DownloadSection;
}(Vue));
export default DownloadSection;
